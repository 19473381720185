<template>
    <v-navigation-drawer
        :value="isFiltersActive"
        temporary
        touchless
        :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
        app
        @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
        <v-card height="100%">
            <div class="drawer-header d-flex align-center mb-4">
                <span class="font-weight-semibold text-base text--primary">{{ $t('Filters') }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
                    <v-icon size="22">
                        {{ mdiClose }}
                    </v-icon>
                </v-btn>
            </div>

            <v-card-text>
                <v-btn @click="deleteFilters" expanded class="d-flex ml-auto text-capitalize" text color="error">
                    {{ $t('DeleteFilters') }}
                    <v-icon right>
                        {{ mdiCloseBoxOutline }}
                    </v-icon>
                </v-btn>
                <div class="mt-4">
                    <v-autocomplete
                        clearable
                        @change="getDistricts"
                        v-model="query.filters.location.parent.parent.parent.id.$containsi"
                        item-value="id"
                        item-text="name"
                        :items="countries"
                        filled
                        shaped
                        :label="$t('Country')"></v-autocomplete>
                    <v-autocomplete
                        clearable
                        @change="getCounties"
                        :items="districts"
                        item-text="name"
                        item-value="id"
                        :disabled="!query.filters.location.parent.parent.parent.id.$containsi"
                        v-model="query.filters.location.parent.parent.id.$containsi"
                        filled
                        shaped
                        :label="$t('District')"></v-autocomplete>
                    <v-autocomplete
                        clearable
                        @change="getParishes"
                        :items="counties"
                        item-text="name"
                        item-value="id"
                        :disabled="!query.filters.location.parent.parent.id.$containsi"
                        v-model="query.filters.location.parent.id.$containsi"
                        filled
                        shaped
                        :label="$t('County')"></v-autocomplete>
                    <v-autocomplete
                        clearable
                        :items="parishes"
                        item-text="name"
                        item-value="id"
                        :disabled="!query.filters.location.parent.id.$containsi"
                        v-model="query.filters.location.id.$containsi"
                        filled
                        :label="$t('Parish')"></v-autocomplete>
                    <v-autocomplete
                        v-model="query.filters.managers.id.$in"
                        filled
                        :label="$t('Managers')"
                        :items="managers"
                        item-text="name"
                        item-value="id"
                        auto-select-first
                        chips
                        clearable
                        deletable-chips
                        multiple></v-autocomplete>
                    <v-range-slider
                        persistent-hint
                        :hint="$t('OcupationTax')"
                        v-model="query.filters.occupationPercentage.$between"
                        min="0"
                        max="100"
                        thumb-label
                        :label="$t('Lotation')"
                        class="mt-4">
                    </v-range-slider>
                    <div class="mt-6">
                        <v-btn @click="applyFilters" color="primary" class="me-3" type="submit">
                            {{ $t('Buttons.Filter') }}
                        </v-btn>
                        <v-btn
                            @click="$emit('update:is-add-new-user-sidebar-active', false)"
                            color="secondary"
                            outlined>
                            {{ $t('Buttons.Cancel') }}
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    import { mdiClose, mdiCloseBoxOutline } from '@mdi/js';
    import { getLocations } from '@/api/location';
    import parkStoreModule from '../parkStoreModule';
    import { getUsers } from '@/api/user';
    //  import useParkList from './useParkList';
    import themeConfig from '@themeConfig';
    import _ from 'lodash';

    export default {
        data() {
            return {
                parkStoreModule,
                countries: [],
                districts: [],
                counties: [],
                parishes: [],
                selectedCountry: null,
                managers: [],
                query: {},
            };
        },
        model: {
            prop: 'isFiltersActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            isFiltersActive: {
                type: Boolean,
                required: true,
            },
        },

        setup() {
            return {
                mdiClose,
                mdiCloseBoxOutline,
            };
        },
        created() {
            this.query = this.$store.getters['app-park/getParkQuery'];
            this.getCountries();
            if (this.query.filters?.location?.parent?.parent?.parent?.id?.$containsi) {
                this.getDistricts();
            }
            if (this.query.filters?.location?.parent?.parent?.id?.$containsi) {
                this.getCounties();
            }
            if (this.query.filters?.location?.parent?.id?.$containsi) {
                this.getParishes();
            }
            this.getUsersNames();
        },
        methods: {
            async getCountries() {
                getLocations({ filters: { level: 0 } }).then((response) => {
                    this.countries = response.data.data;
                });
            },
            getDistricts() {
                this.districts = [];
                this.counties = [];
                this.parishes = [];
                getLocations({
                    filters: { parent: this.query.filters.location.parent.parent.parent.id.$containsi },
                }).then((response) => {
                    this.districts = response.data.data;
                });
            },
            getCounties() {
                this.counties = [];
                this.parishes = [];
                getLocations({ filters: { parent: this.query.filters.location.parent.parent.id.$containsi } }).then(
                    (response) => {
                        this.counties = response.data.data;
                    },
                );
            },
            getParishes() {
                getLocations({ filters: { parent: this.query.filters.location.parent.id.$containsi } }).then(
                    (response) => {
                        this.parishes = response.data.data;
                    },
                );
            },
            applyFilters() {
                this.query.pagination.page = 1;
                if (_.get()) this.$store.commit('app-park/setParkQuery', this.query);
                this.$emit('setQuery');
                this.$emit('update:is-add-new-user-sidebar-active', false);
            },
            deleteFilters() {
                this.query.filters.occupationPercentage.$between = [0, 100];
                this.query.filters.managers.id.$in = '';
                this.query.filters.location.parent.parent.parent.id.$containsi = '';
                this.query.filters.location.parent.parent.id.$containsi = '';
                this.query.filters.location.parent.id.$containsi = '';
                this.query.filters.location.id.$containsi = '';
            },
            async getUsersNames() {
                getUsers().then((response) => {
                    this.managers = response.data.data;
                });
            },
        },
    };
</script>
